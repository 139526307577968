
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TranslateAndImprove = ({ apiEndpoint = '/api/translate-and-improve' }) => {
  const [response, setResponse] = useState(null);
  const [sentence, setSentence] = useState('');
  const [loading, setLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/csrf-token`, { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    getCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${apiEndpoint}`,
        { sentence },
        { headers: { 'CSRF-Token': csrfToken }, withCredentials: true }
      );
      setResponse(JSON.parse(response.data));
    } catch (error) {
      console.error('Error translating and improving sentence:', error);
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  useEffect(() => {
    if (response && response.correct_part) {
      navigator.clipboard.writeText(response.correct_part)
        .then(() => {
          setNotification('Corrected part copied to clipboard');
          setTimeout(() => {
            setNotification('');
          }, 3000);
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }, [response]);

  return (
    <div className="container mx-auto p-4">
      {notification && (
        <div className="fixed top-4 right-4 bg-green-500 text-white p-2 rounded">
          {notification}
        </div>
      )}
      <div className="content bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            rows="3"
            value={sentence}
            onChange={(e) => setSentence(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter a sentence in Vietnamese"
            className="text-sm w-full p-2 border border-gray-300 dark:border-gray-700 rounded-lg"
          />
          <button
            disabled={loading}
            type="submit"
            className="px-3 py-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm text-center me-2 mb-2"
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
        {loading && (
          <div className="loading-icon mt-4">
            <p>Loading...</p>
          </div>
        )}
        {response && (
          <div className="response mt-4">
            <div className="response-container">
              <div className="text-xs font-semibold mt-6">Translated and Improved Sentence:</div>
            </div>
              <p className="translated-part mt-2 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg ">
                <span className="text-lg">{response.correct_part}</span>
              </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TranslateAndImprove;

