import React from 'react';
import { Link } from 'react-router-dom';
import { BookOpenIcon, LanguageIcon } from '@heroicons/react/24/outline';

const LeftNav = () => {
    return (
        <div className="w-64 h-full flex flex-col">
            <div className="p-4 text-xl font-semibold">
                MyApp
            </div>
            <nav className="flex-1">
                <ul>
                    <li className="p-4 hover:bg-blue-500 flex items-center">
                        <BookOpenIcon className="h-6 w-6 mr-2" />
                        <Link to="/grammar-correction">Grammar Correction</Link>
                    </li>
                    <li className="p-4 hover:bg-blue-400 flex items-center">
                        <LanguageIcon className="h-6 w-6 mr-2" />
                        <Link to="/translate-correction">Translate Correction</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default LeftNav;

