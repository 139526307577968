import React from 'react';
import CorrectionInput from '../components/CorrectionInput';

const GrammarCorrection = () => {
    return (
        <div className="container mx-auto p-4">
            <div className="content bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
                <div>Grammar Correction</div>
                <CorrectionInput />
            </div>
        </div>
    );
}

export default GrammarCorrection;

