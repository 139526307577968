import React from 'react';
import LeftNav from '../components/LeftNav';
import DarkModeToggle from '../components/DarkModeToggle';

const Layout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col dark:bg-gray-900 dark:text-white">
            <header className="bg-blue-600 dark:bg-gray-800 text-white p-4 flex justify-between items-center">
                <h1 className="text-2xl">MyApp</h1>
                <DarkModeToggle />
            </header>
            <div className="flex flex-1">
                <LeftNav />
                <main className="flex-1 p-4 bg-gray-100 dark:bg-gray-800">
                    {children}
                </main>
            </div>
            <footer className="bg-gray-800 dark:bg-gray-900 text-white p-4 text-center">
                © 2024 MyApp. All rights reserved.
            </footer>
        </div>
    );
};

export default Layout;

