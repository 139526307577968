import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CorrectionInput = ({ addQuestion }) => {
  const [response, setResponse] = useState('');
  const [sentence, setSentence] = useState('');
  const [loading, setLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/csrf-token`, { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    getCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/correct`,
        { sentence },
        { headers: { 'CSRF-Token': csrfToken }, withCredentials: true }
      );
      setResponse(JSON.parse(response.data));
    } catch (error) {
      console.error('Error correcting sentence:', error);
    }
    setLoading(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="content bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            rows="10"
            value={sentence}
            onChange={(e) => setSentence(e.target.value)}
            placeholder="Enter a sentence"
            className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded-lg"
          />
          <button
            disabled={loading}
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50"
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
        {loading && (
          <div className="loading-icon mt-4">
            {/* Your loading icon component */}
          </div>
        )}
        {response && (
          <div className="response bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mt-4">
            <div className="response-container">
              <h3 className="text-lg font-semibold">Corrected Part:</h3>
              <p className="corrected-part mt-2">
                {response.correct_part}
              </p>
              <h3 className="improvements text-lg font-semibold mt-4">Improvements:</h3>
              {response.improvement_part.map((item, index) => (
                <table key={index} className="w-full mt-2 table-auto">
                  <thead>
                    <tr className="bg-gray-200 dark:bg-gray-600">
                      <th className="px-4 py-2">Original</th>
                      <th className="px-4 py-2">Corrected</th>
                      <th className="px-4 py-2">Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">{item.original}</td>
                      <td className="border px-4 py-2">{item.corrected}</td>
                      <td className="border px-4 py-2">{item.feedback}</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CorrectionInput;
