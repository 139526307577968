import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import GrammarCorrection from './pages/GrammarCorrection';
import TranslateCorrection from './pages/TranslateCorrection';

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<GrammarCorrection />} />
                    <Route path="/grammar-correction" element={<GrammarCorrection />} />
                    <Route path="/translate-correction" element={<TranslateCorrection />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;

