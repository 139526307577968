import React from 'react';
import TranslateAndImprove from '../components/TranslateAndImprove';

const TranslateCorrection = () => {
    return (
        <div className="container mx-auto p-4">
            <div className="content bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
                <div>Translate</div>
                <TranslateAndImprove />
            </div>
        </div>
    );
};

export default TranslateCorrection;

